<template>
  <div :class="wrapperClasses">
    <div v-if="layout === 'material' && optional" class="text-right text-xs italic mt-3 opacity-75">
      Optional
    </div>
    <div class="w-full flex flex-row items-center">
      <div class="w-full flex-grow" :class="innerWrapperClasses">
        <template v-if="type != 'checkbox' && type != 'radio'">
          <yb-icon v-if="icon" :icon="icon" class="yb-button-icon" />
          <label v-if="label" :for="idValue" :class="groupedLabelClasses" class="select-none" v-text="label" />
          <slot v-else />
        </template>
        <input
          v-bind="options"
          :id="idValue"
          ref="input"
          v-model="inputValue"
          :name="name"
          :data-test-id="dataTestId"
          :type="type"
          :autocomplete="autocomplete"
          :autofocus="autofocus"
          :disabled="disabled"
          :readonly="readonly && readonlyDerived"
          :required="required"
          :class="inputClasses"
          @input="input"
          @change="change"
          @focus="focus"
          @blur="blur"
          @click="$emit('click', $event)"
          @keydown="$emit('keydown', $event)"
        >
        <template v-if="type == 'checkbox' || type == 'radio'">
          <yb-icon v-if="icon" :icon="icon" class="yb-button-icon" />
          <label v-if="label" :for="idValue" :class="groupedLabelClasses" class="select-none cursor-pointer ml-1.5" v-text="label" />
          <slot v-else />
        </template>
      </div>

      <slot name="side" />

      <div v-if="help" class="w-12 flex flex-row justify-center items-center flex-grow-0 flex-shrink-0" :class="helpClass ? helpClass : ''">
        <yb-icon v-tooltip="{content: help, placement: 'bottom'}" class="h-5 w-5 cursor-pointer" icon="regular-question" />
      </div>

      <datalist :id="'datalist-' + idValue">
        <option v-for="(value, index) in datalist" :key="index" :value="value" />
      </datalist>
    </div>

    <p v-if="validation && validation.$dirty && validation.$invalid && validation.url && validation.url.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0" data-test-id="inline-error-url">
      Please enter a valid url.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.alreadyExists && validation.alreadyExists.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This name already exists.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.required && validation.required.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field is required.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.pattern && validation.pattern.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      <template v-if="validation.pattern.$params.message">
        {{ validation.pattern.$params.message }}
      </template>
      <template v-else-if="validation.pattern.$params.type === 'hostname'">
        This field must begin and end with a letter or number and may only contain letter, number or hyphen characters.
      </template>
      <template v-else-if="validation.pattern.$params.type === 'bucket'">
        This field may only contain letter, number, hypen or dot characters.
      </template>
      <template v-else>
        This field must conform to "{{ validation.pattern.$params.type }}" pattern rules.
      </template>
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.minLength && validation.minLength.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field has a minimum length of {{ validation.minLength.$params.min }}.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.maxLength && validation.maxLength.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field has a maximum length of {{ validation.maxLength.$params.max }}.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.between && validation.between.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field has a must be set between {{ validation.between.$params.min }} and {{ validation.between.$params.max }}.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.maxValue && validation.maxValue.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field has a maximum value of {{ validation.maxValue.$params.max }}.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.minValue && validation.minValue.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field has a minimum value of {{ validation.minValue.$params.min }}.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.sameAs && validation.sameAs.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      This field must match.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.email && validation.email.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      Please enter a valid email address.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.ipAddress && validation.ipAddress.$invalid" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      Please enter a valid IP address.
    </p>
    <p v-else-if="validation && validation.$dirty && validation.$invalid && validation.$errors && validation.$errors.length && validation.$errors[0].$message" class="text-left yb-error-text text-sm mt-0.5 mb-4 h-0">
      {{ validation.$errors[0].$message }}
    </p>
  </div>
</template>

<script lang="ts">
import { v1 as uuidv1 } from 'uuid'

export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: false
    },
    name: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    validationMessage: {
      type: String,
      default: 'Invalid value found'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: [Boolean, String],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String as PropType<string|String>,
      default: null,
      required: false
    },
    labelClass: {
      type: String,
      default: ''
    },
    materialClass: {
      type: String,
      default: 'py-3 text-sm'
    },
    widthClass: {
      type: String,
      default: 'w-full'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    autoReadwrite: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    dataTestId: {
      type: String
    },
    layout: {
      type: String,
      default(rawProps) {
        if (rawProps.type === 'checkbox') {
          return 'default'
        } else {
          return 'material'
        }
      }
    },
    validation: {
      type: Object
    },
    datalist: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    optional: Boolean,
    help: {
      type: String,
      required: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    radioValue: {
      type: [String, Number, Boolean],
      required: false
    },
    inputClass: {
      type: String,
      required: false,
      default: 'yb-input px-4'
    },
    helpClass: {
      type: String,
      required: false,
      default: ''
    },
    relativeTarget: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['focus', 'blur', 'update:modelValue', 'click', 'keydown'],
  data() {
    return {
      inputValue: this.modelValue,
      readonlyDerived: true
    }
  },
  computed: {
    idValue() {
      return this.id || this.componentId
    },
    wrapperClasses() {
      const result = []
      const { layout, widthClass } = this
      if (layout === 'material') {
        result.push('is-material')
      }
      result.push(...this.layoutClasses)
      result.push(widthClass)
      return result
    },
    innerWrapperClasses() {
      const result = []
      result.push(...this.layoutClasses)
      if (this.disabled) {
        result.push('opacity-50', 'pointer-events-none')
      }
      return result
    },
    layoutClasses() {
      const result = []
      const { layout } = this
      result.push('flex')
      if (this.relativeTarget) {
        result.push('relative')
      }
      result.push('group')
      if (layout === 'horizontal' || this.type === 'checkbox' || this.type === 'radio') {
        result.push('flex-row')
        result.push('flex-nowrap')
        result.push('items-center')
        result.push('space-x-2')
      } else {
        result.push('flex-col')
      }
      return result
    },
    groupedLabelClasses() {
      const result = []
      const hasValue = typeof this.modelValue !== 'undefined' && this.modelValue !== null && this.modelValue !== ''
      if (this.layout === 'material') {
        result.push(!hasValue ? 'text-sm' : 'text-xs')
        result.push(!hasValue ? (this.optional ? 'top-12' : 'top-4') : (this.optional ? 'top-3' : '-top-1.5'))
        result.push('absolute', 'px-2', 'left-2', 'transition-all', 'font-light')
        result.push(!hasValue ? 'opacity-75' : 'yb-body-background', 'bg-opacity-100')
      } else if (this.type === 'checkbox' || this.type === 'radio') {
        result.push('pr-8')
      }
      result.push(this.labelClass)
      result.push('whitespace-nowrap')
      return result
    },
    inputClasses() {
      const result = []
      if (this.layout === 'material' && this.type !== 'radio' && this.type !== 'checkbox') {
        result.push(this.materialClass)
      }
      if (this.type !== 'checkbox' && this.type !== 'radio') {
        result.push('flex-grow')
        result.push(this.inputClass)
      }
      const { validation } = this
      if (validation && validation.$dirty && validation.$invalid) {
        result.push('!border-red-400')
        result.push('!border-2')
        result.push('!focus:border-2')
        result.push('!ring-red-400')
        result.push('!rounded')
      }
      return result
    },
    options() {
      const result = {}
      if (typeof this.min !== 'undefined') {
        result.min = this.min
      }
      if (typeof this.max !== 'undefined') {
        result.max = this.max
      }
      if (this.type !== 'checkbox' && this.type !== 'radio' && !!this.idValue) {
        result.list = 'datalist-' + this.idValue
      }
      if (this.type === 'radio') {
        result.value = this.radioValue
      }
      return result
    }
  },
  mounted() {
    this.maybeFocus()
  },
  watch: {
    modelValue(_) {
      this.inputValue = _
    }
  },
  beforeCreate() {
    this.componentId = `input-${uuidv1()}`
  },
  methods: {
    focus($event) {
      this.$emit('focus', $event)
      if (this.autoReadwrite) {
        this.readonlyDerived = false
      }
    },
    blur($event) {
      this.$emit('blur', $event)
      if (typeof this.validation?.$touch === 'function') {
        this.validation.$touch()
      }
    },
    input($event) {
      if (this.type !== 'checkbox' && this.type !== 'radio') {
        this.$emit('update:modelValue', $event.target.value)
      }
    },
    change($event) {
      if (this.type === 'checkbox' || this.type === 'radio') {
        this.$emit('update:modelValue', this.inputValue)
      }
    },
    setFocus() {
      this.$refs.input.focus()
    },
    maybeFocus() {
      const { autofocus } = this
      if (!!autofocus) {
        this.setFocus()
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.is-material .yb-input {
  @apply pl-4 mt-1;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}

.yb-input[type='radio'] {
  @apply rounded-full;
}

</style>
